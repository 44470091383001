import styled, { css, keyframes } from 'styled-components'

import { NewTabLink } from '@src/components/NewTabLink'
import { Link } from '@src/components/Router/Link'
import { breakpoints } from '@src/constants/breakpoints'

export const OrderInfoContainer = styled.div`
  margin-bottom: 24px;
  margin-top: 16px;
  border: 2px solid #ebecf0;
  border-radius: 8px;
`

export const LightText = styled.span`
  font-weight: 400;
  font-size: 14px;
  padding: 0px;
  margin: 0px;
`

export const OutletPhotoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`

export const RestaurantLogo = styled.div<{
  imageUrl: string
}>`
  height: 80px;
  width: 80px;
  border-radius: 4px;
  background-color: #f6f6f9;
  background-size: cover;

  ${props =>
    props.imageUrl &&
    css`
      background-image: url(${props.imageUrl});
    `};
`

export const OutletName = styled.p`
  font-weight: 700;
  font-size: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
`

export const TotalContainer = styled.table`
  border-radius: 4px;
  padding: 18px;
  width: 100%;
  border-spacing: 0;
  @media (min-width: ${breakpoints.largeTablet}px) {
    padding: 16px 16px 16px;
  }
`

export const TotalSubContainer = styled.dl`
  margin: 0 0 16px 0;
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.brand};
`

export const ChargesSubContainer = styled.dl`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ChargesIndividual = styled.tr`
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  border-spacing: 0;
  margin-bottom: 8px;
`

export const ChargesIndividualLarger = styled.tr`
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  padding-bottom: 4px;
  border-spacing: 0;
`

export const DiscountIndividualLarger = styled.tr`
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  padding-bottom: 4px;
  border-spacing: 0;
  color: ${props => props.theme.colors.discount};
`

export const DeliveryContainer = styled.div<{ noBorder?: boolean }>`
  border-bottom: ${props =>
    props.noBorder ? 'no-border' : '2px solid #ebecf0'};
  @media (min-width: ${breakpoints.largeTablet}px) {
    border-bottom: ${props =>
      props.noBorder ? 'no-border' : '2px solid #ebecf0'};
  }
  padding: 0px 16px 16px 16px;
`

export const BoldTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 4px;
`

export const OutletNumber = styled(BoldTitle)`
  padding-top: 8px;
`

export const HelpTitle = styled.p`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.brand};
`

export const HelpContainer = styled.div`
  padding: 24px 16px;
  height: auto;
  border-top: 2px solid #ebecf0;
  @media (min-width: ${breakpoints.largeTablet}px) {
    border-top: 2px solid #2e3333;
  }
`

export const StatusBoxProgressCont = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  border-radius: 3px;
  background-clip: padding-box;
  overflow: hidden;
`

const gradientAnimation = keyframes`
  0% {
    background-position: 100% 0%;
  }
  40% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 0% 100%;
  }
`

export const LoadingBarContainer = styled.div`
  ${({ theme }) => {
    const backgroundColor = theme.colors.openStatus
    return css`
      width: 100%;
      height: 8px;
      margin: 0;
      background: linear-gradient(
        90deg,
        ${backgroundColor} 30%,
        #ffffff80 50%,
        ${backgroundColor} 70%
      );
      background-size: 300% 100%;
      position: relative;
      overflow: hidden;
      animation: ${gradientAnimation} 2s cubic-bezier(0.4, 0, 1, 1) infinite;
    `
  }}
`

export const LoadingBarFill = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 10px;
`

export const StatusBoxProgressBarEmpty = styled.div`
  background-color: ${({ theme }) => theme.colors.grey};
  width: 100%;
  height: 8px;
  margin: 0px 0 0 2px;
  position: relative;
`

export const StatusBoxProgressBarCompleted = styled.div<{ isDanger: boolean }>`
  background-color: ${({ theme, isDanger }) =>
    isDanger ? theme.colors.danger : theme.colors.openStatus};
  width: 100%;
  height: 8px;
  margin: 0 2px;
  position: relative;
`

export const HelpAndSupportText = styled.span`
  cursor: pointer;
  font-weight: 700;
  margin: 0;
  a {
    color: inherit;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`

const StyledLink = styled(Link)`
  display: flex;
`

export const StyledNewTabLink = StyledLink.withComponent(NewTabLink)

export const InfoAndIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  align-items: center;
`

export const LoyaltyCardImage = styled.div<{
  imageUrl: string
}>`
  background-size: cover;
  min-width: 24px;
  min-height: 24px;
  background-color: grey;
  border-radius: 30px;
  filter: drop-shadow(-2px 2px 2px rgba(0, 0, 0, 0.2));
  margin-right: 8px;

  ${props =>
    props.imageUrl &&
    css`
      background-image: url(${props.imageUrl});
    `};

  clip-path: url(#scalloped-mask-0.42);
`
export const StampInfo = styled.div`
  margin-right: 8px;
`
