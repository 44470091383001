import { useQuery } from '@apollo/client'
import { kebabCase, lowerCase, shuffle } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { BusinessSegmentCategories } from '@src/components/BusinessSegments/BusinessSegmentCategories'
import {
  BusinessSegmentOutlets,
  OutletOrLinkCard,
} from '@src/components/BusinessSegments/BusinessSegmentOutlets'
import { ErrorPage } from '@src/components/Errors/ErrorPage'
import { FeaturedOutlets } from '@src/components/FeaturedOutlets'
import {
  BusinessSegment,
  BusinessSegmentType,
  Cuisine,
  LocationType,
} from '@src/graphql-types'
import { clearNonBasketOutletFulfilments } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/utils/clearNonBasketFulfilments'
import { useMarketplaceDeliveryZonesQuery } from '@src/hooks/sharedQueries/useMarketplaceDeliveryZones/useMarketplaceDeliveryZones'
import { useCategories } from '@src/hooks/useCategories'
import { useFulfilmentFilter } from '@src/hooks/useFulfilmentFilter/useFulfilmentFilter'
import { useMarketplace } from '@src/hooks/useMarketplace'
import {
  ALL_FULFILMENT_METHODS_SORTED,
  FULFILMENTS_NO_TABLE,
} from '@src/utils/fulfilment'
import { parseOutletDates } from '@src/utils/fulfilmentTimes/parsers'
import { sortOutlets } from '@src/utils/sortOutlets'

import { CategoryFilteredOutlets } from './CategoryFilteredOutlets'
import { getOutletSearchVariables } from './getOutletSearchVariables'
import { OutletCards } from './OutletCards'
import { HeaderContainer, OutletWrapper } from './OutletList.styles'
import { OutletListCompactSkeleton } from './OutletListCompactSkeleton'
import { outletListDocument } from './queries/__generated__/outletList.graphql-interface'

import { getBusinessSegmentsDocument } from '../Home/queries/__generated__/getBusinessSegments.graphql-interface'

export const OutletList: React.FC<{ deliveryZone?: string }> = ({
  deliveryZone,
}) => {
  const marketplace = useMarketplace()
  const fulfilmentFilter = useFulfilmentFilter()
  const { t } = useTranslation('copy')
  const { selectedCategories } = useCategories()

  const categoryIds = selectedCategories.map(({ id }) => id)

  const deliveryZoneResponse = useMarketplaceDeliveryZonesQuery({
    useQueryArgs: {
      skip:
        fulfilmentFilter.data.where.location.type !==
        LocationType.DELIVERY_ZONE,
    },
  })

  useEffect(() => {
    clearNonBasketOutletFulfilments()
  }, [])

  useEffect(() => {
    if (
      deliveryZone &&
      deliveryZoneResponse.data &&
      deliveryZoneResponse.data?.deliveryZonesByMarketplaceId.length > 0
    ) {
      const selectedZoneId =
        deliveryZoneResponse.data?.deliveryZonesByMarketplaceId.find(
          zone => lowerCase(kebabCase(zone.name)) === lowerCase(deliveryZone)
        )?.id

      if (
        selectedZoneId &&
        (fulfilmentFilter.data.where.location.type !==
          LocationType.DELIVERY_ZONE ||
          fulfilmentFilter.data.where.location.zoneId !== selectedZoneId)
      ) {
        fulfilmentFilter.setLocationType({
          type: LocationType.DELIVERY_ZONE,
          zoneId: selectedZoneId,
        })
      }
    }
  }, [deliveryZone, deliveryZoneResponse, fulfilmentFilter])

  const variables = getOutletSearchVariables({
    fulfilmentFilter: fulfilmentFilter.data,
    marketplaceId: marketplace.id,
    cuisineIds: categoryIds,
  })

  const { data, loading, error } = useQuery(outletListDocument, {
    variables: variables ?? undefined,
  })

  const segmentVariables = {
    fulfilmentMethod: fulfilmentFilter.data.priorityFulfilmentMethod,
    acceptedFulfilmentMethods: fulfilmentFilter.data.nonEmptyFulfilmentMethods,
  }

  const shuffledOutlets = useMemo(() => {
    if (data?.outlets) {
      return shuffle(data?.outlets.map(parseOutletDates))
    } else return []
  }, [data])

  const segmentsResponse = useQuery(getBusinessSegmentsDocument, {
    variables: {
      outletsInput: variables.input,
      marketplaceId: marketplace.id,
      businessSegmentTypes: [
        BusinessSegmentType.CATEGORIES,
        BusinessSegmentType.OUTLETS,
      ],
      fulfilmentMethod: segmentVariables.fulfilmentMethod,
      acceptedFulfilmentMethods: segmentVariables.acceptedFulfilmentMethods,
      fulfilmentLocation: fulfilmentFilter.data.where.location,
      fulfilmentTimeType: fulfilmentFilter.data.timeType,
    },
  })

  if (loading) {
    return <OutletListCompactSkeleton />
  }
  if (error) {
    return <ErrorPage logError={error} />
  }
  if (!data) {
    return <ErrorPage />
  }

  const outlets = sortOutlets(
    shuffledOutlets,
    fulfilmentFilter.data,
    selectedCategories
  )

  const allFulfilmentMethods = marketplace.allowOrderToTable
    ? ALL_FULFILMENT_METHODS_SORTED
    : FULFILMENTS_NO_TABLE

  const fulfilmentsTitle =
    fulfilmentFilter.data.nonEmptyFulfilmentMethods.length ===
    allFulfilmentMethods.length
      ? marketplace.allOutletsText || t('all')
      : fulfilmentFilter.data.nonEmptyFulfilmentMethods
          .map(fulfilmentMethod => t(fulfilmentMethod.toLowerCase()))
          .join(', ')

  return (
    <OutletWrapper>
      {selectedCategories.length ? (
        <CategoryFilteredOutlets outlets={outlets} />
      ) : (
        <>
          {!segmentsResponse.data?.businessSegments.length && (
            <FeaturedOutlets
              title={t('now_on', {
                marketplaceName: marketplace.name,
              })}
            />
          )}
          {segmentsResponse.data?.businessSegments.map((segment, index) => (
            <>
              {segment.type === BusinessSegmentType.OUTLETS && (
                <BusinessSegmentOutlets
                  key={`segmentOutlets-${index}`}
                  businessSegment={{
                    ...segment,
                    cards: segment.cards.map(card => {
                      if (card.__typename === 'Outlet') {
                        return parseOutletDates(card)
                      }
                      return card
                    }) as OutletOrLinkCard[],
                  }}
                />
              )}
              {segment.type === BusinessSegmentType.CATEGORIES && (
                <BusinessSegmentCategories
                  key={`segmentCategories-${index}`}
                  segment={
                    segment as Pick<
                      BusinessSegment,
                      'id' | 'name' | 'showName' | 'description'
                    > & {
                      cards: Cuisine[]
                    }
                  }
                />
              )}
            </>
          ))}
          {outlets.length > 0 && (
            <HeaderContainer>{fulfilmentsTitle}</HeaderContainer>
          )}
          <OutletCards outlets={outlets}></OutletCards>
        </>
      )}
    </OutletWrapper>
  )
}
