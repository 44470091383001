import { useQuery, useReactiveVar } from '@apollo/client'
import { groupBy } from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import ReactPixel from 'react-facebook-pixel'
import { useTranslation } from 'react-i18next'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'

import { Helmet } from '@src/components/Helmet'
import { Redirect } from '@src/components/Router/Redirect'
import { breakpoints } from '@src/constants/breakpoints'
import {
  NarrowFulfilmentMethodInputType,
  MenuItemGroupFulfillmentMethod,
  OutletMenuItemGroup,
} from '@src/graphql-types'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { CurrentFulfilmentType } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/validation'
import { useAllDiscountsQuery } from '@src/hooks/sharedQueries/useAllDiscounts/useAllDiscounts'
import { useLazyTablesQuery } from '@src/hooks/sharedQueries/useTables/useTables'
import { useBreakpoint } from '@src/hooks/useBreakpoint'
import { useCheckoutRouter } from '@src/hooks/useCheckoutRouter/useCheckoutRouter'
import {
  useIntersectionObservableRefCallback,
  useIntersectionObserver,
} from '@src/hooks/useIntersectionObserver'
import { useShowInactiveQueryParam } from '@src/hooks/useShowInactiveQueryParam'
import { screenResolutionVar } from '@src/models/screenResolution'

import { OutletMenuItemGroupTree } from './menuItemGroupTreeType'
import { OutletHeader } from './OutletHeader/OutletHeader'
import { OutletMenu } from './OutletMenu/OutletMenu'
import { GetOutletMenuDocument } from './OutletMenu/queries/__generated__/GetOutletMenu.graphql-interface'
import { OutletMenuSection } from './OutletMenuSections/OutletMenuSection'
import { OutletOverview } from './OutletOverview/OutletOverview'
import {
  ObservableDiv,
  MenuWrapper,
} from './OutletOverview/OutletOverview.styles'
import { StickyHeader, HeaderWrapper } from './OutletPage.styles'
import { OutletPageSkeleton } from './OutletPageSkeleton/OutletPageSkeleton'
import { OutletStackedMenu } from './OutletStackedMenu/OutletStackedMenu'
import { OutletStackedMenuView } from './OutletStackedMenu/OutletStackedMenuView'

export const ONE_MINUTE = 60 * 1000

export const MAPPED_FULFILMENT_METHODS: Record<
  NarrowFulfilmentMethodInputType,
  MenuItemGroupFulfillmentMethod
> = {
  [NarrowFulfilmentMethodInputType.DELIVERY]:
    MenuItemGroupFulfillmentMethod.DELIVERY,
  [NarrowFulfilmentMethodInputType.COLLECTION]:
    MenuItemGroupFulfillmentMethod.COLLECTION,
  [NarrowFulfilmentMethodInputType.TABLE]: MenuItemGroupFulfillmentMethod.TABLE,
}

export const OutletPage: React.FC = () => {
  const {
    data: { currentFulfilment },
    outlet,
    setCurrentFulfilment,
  } = useOutletFulfilment({ stateType: OutletFulfilmentStateType.GLOBAL })
  const [menuItemGroupTreeLastUpdated, setMenuItemGroupTreeLastUpdated] =
    useState(new Date())
  const { path } = useRouteMatch()
  const [showInactive] = useShowInactiveQueryParam()
  const { width } = useReactiveVar(screenResolutionVar)
  const checkoutRouter = useCheckoutRouter()
  const headerRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation('outletOverview')

  const [menuSearchIds, setMenuSearchIds] = useState<string[]>([])
  const [showMenuSearchResults, setShowMenuSearchResults] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string>('')

  const [headerHeight, setHeaderHeight] = useState<number | null>(null)
  const [isCollapsingDivVisible, setIsCollapsingDivVisible] = useState(false)
  const [activeMenuId, setActiveMenuId] = useState<string | null>(null)
  const { isMobile, isTablet } = useBreakpoint()
  const [manualNavigation, setManualNavigation] = useState(false)
  const [tableIdQueryParam, setTableIdQueryParam] = useQueryParam(
    'table',
    StringParam
  )
  const [tablesQuery] = useLazyTablesQuery({
    outletId: outlet.id,
  })
  const isMobileStackedMenuItemsPageView = useRouteMatch(
    `${path}/menu/:menuGroupId`
  )

  const restaurant = outlet.restaurant
  const isStackedMenu =
    restaurant.enableStackedMenu && width < breakpoints.tablet

  useEffect(() => {
    const height = headerRef.current?.offsetHeight ?? null
    if (!isCollapsingDivVisible) setHeaderHeight(height)
  }, [outlet, width, headerRef.current])

  const { displayPermanentCheckout } = useCheckoutRouter()

  // fire pixel ViewContent event when outlet page is loaded
  useEffect(() => {
    if (outlet.id) {
      ReactPixel.track('ViewContent', {
        content_ids: outlet.id,
        content_type: 'outlet',
        content_name: outlet.displayName,
      })
    }
  }, [outlet.displayName, outlet.id])

  useEffect(() => {
    if (tableIdQueryParam) {
      void tablesQuery({
        onCompleted: ({ tables }) => {
          if (tables.some(table => table.id === tableIdQueryParam)) {
            setCurrentFulfilment({
              type: CurrentFulfilmentType.TABLE,
              tableId: tableIdQueryParam,
            })
          } else {
            setCurrentFulfilment({
              type: CurrentFulfilmentType.TABLE_UNSELECTED,
            })
          }
          setTableIdQueryParam(undefined)
        },
      })
    }
  }, [
    setCurrentFulfilment,
    setTableIdQueryParam,
    tableIdQueryParam,
    tablesQuery,
  ])

  const { intersectionObserver } = useIntersectionObserver({
    callback: function callbackCollapseHeader(entries) {
      const [entry] = entries

      if (entry && entry.isIntersecting) {
        setIsCollapsingDivVisible(false)
      } else {
        setIsCollapsingDivVisible(true)
      }
    },
    options: {
      root: null,
      rootMargin: '0px',
      threshold: 1,
    },
  })

  // get menuItemGroups and skip if stacked menu
  const { data: menuItemGroupsQueryResponse, previousData } = useQuery(
    GetOutletMenuDocument,
    isStackedMenu
      ? { skip: true }
      : {
          variables: {
            outletId: outlet.id,
            endOfPrep: showInactive
              ? undefined
              : currentFulfilment.endOfPrep?.toISOString(),
            showInactive,
            fulfilmentMethods: [],
          },
        }
  )

  const unfilteredMenuItemGroups: OutletMenuItemGroup[] =
    ((menuItemGroupsQueryResponse || previousData)
      ?.menuItemGroupsForOutlet as OutletMenuItemGroup[]) ?? []

  const menuItemGroups = unfilteredMenuItemGroups
    ? unfilteredMenuItemGroups.filter(
        menuItemGroup =>
          (showInactive ||
            !outlet.hiddenMenuItemGroupIds.includes(menuItemGroup.id)) &&
          menuItemGroup.narrowFulfilmentMethods.includes(
            currentFulfilment.narrowType
          ) &&
          !menuItemGroup.addOnItemsMenu
      )
    : []

  const processMenuItemGroups = (menuItemGroups: OutletMenuItemGroup[]) => {
    if (!menuItemGroups) {
      return []
    }

    // Filter out menu items that have add-on items
    const menuItemsWithoutAddonItems = menuItemGroups.filter(
      ({ addOnItemsMenu }) => !addOnItemsMenu
    )

    // Group items into parent menus and submenus
    const { parentMenus = [], subMenus = [] } = groupBy(
      menuItemsWithoutAddonItems,
      menuItemGroup => (menuItemGroup.parentMenu ? 'subMenus' : 'parentMenus')
    )

    // Nest submenus under parent menus
    return parentMenus.map(parentMenu => {
      const subMenusForParentMenu = subMenus.filter(
        subMenu => subMenu.parentMenu?.id === parentMenu.id
      )
      return {
        parentMenu,
        subMenus: subMenusForParentMenu,
      }
    })
  }

  // memoized function which:
  // 1. groups menu item groups into parent menus and submenus
  // 2. adds properties to parent and sub menus to indicate their availability
  // 3. returns parent menus with their submenus nested under them
  const menuItemGroupTree = useMemo<OutletMenuItemGroupTree>(() => {
    return processMenuItemGroups(menuItemGroups)
  }, [
    menuItemGroupTreeLastUpdated,
    menuItemGroups,
    currentFulfilment.endOfPrep,
    currentFulfilment.narrowType,
  ])

  // useMemo hook for fullMenuItemGroupTree
  const fullMenuItemGroupTree = useMemo<OutletMenuItemGroupTree>(() => {
    return processMenuItemGroups(unfilteredMenuItemGroups)
  }, [
    menuItemGroupTreeLastUpdated,
    unfilteredMenuItemGroups,
    currentFulfilment.endOfPrep,
    currentFulfilment.narrowType,
  ])
  // every minute update the availability (by updating the state, which triggers a rerender)
  useEffect(() => {
    const interval = setInterval(() => {
      setMenuItemGroupTreeLastUpdated(new Date())
    }, ONE_MINUTE)
    return () => clearInterval(interval)
  }, [])

  const [expandHeaderRef] = useIntersectionObservableRefCallback({
    intersectionObserver,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { data, loading: discountsLoading } = useAllDiscountsQuery({
    outletId: outlet.id,
    fulfilmentNarrowType: currentFulfilment.narrowType,
  })

  const dealsAndOffersMenuItem = {
    parentMenu: {
      id: 'discountMenuItem',
      name: t('deals_and_offers'),
    },
  }

  if ((!menuItemGroups && !isStackedMenu) || discountsLoading) {
    return <OutletPageSkeleton />
  }

  const updatedAvailableMenuItemGroupTree =
    data && data.allDiscountsByOutletId.length
      ? [dealsAndOffersMenuItem, ...menuItemGroupTree]
      : [...menuItemGroupTree]

  const defaultedActiveMenuId =
    activeMenuId ||
    (data && data.allDiscountsByOutletId.length
      ? 'discountMenuItem'
      : menuItemGroupTree[0]?.parentMenu.id) ||
    null

  return (
    <>
      <Helmet
        title={outlet.displayName}
        description={restaurant.metaDescription}
        keywords={restaurant.metaKeywords}
      />

      {!isMobileStackedMenuItemsPageView && (
        <StickyHeader ref={headerRef} compactStyle={isCollapsingDivVisible}>
          <HeaderWrapper
            permanentCheckoutOpen={displayPermanentCheckout}
            checkoutOpen={checkoutRouter.route !== undefined}
          >
            <OutletOverview
              compactStyle={isCollapsingDivVisible}
              permanentCheckout={displayPermanentCheckout}
            />
            <OutletHeader
              compactStyle={
                isMobile || isTablet ? false : isCollapsingDivVisible
              }
            />
            {!isStackedMenu && (
              <OutletMenuSection
                parentMenus={updatedAvailableMenuItemGroupTree.map(
                  ({ parentMenu }) => parentMenu
                )}
                activeMenuId={defaultedActiveMenuId}
                setManualNavigation={setManualNavigation}
                setActiveMenuId={setActiveMenuId}
                isCollapsingDivVisible={isCollapsingDivVisible}
                outletId={outlet.id}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                setMenuSearchIds={setMenuSearchIds}
                setShowMenuSearchResults={setShowMenuSearchResults}
                showMenuSearchResults={showMenuSearchResults}
              />
            )}
          </HeaderWrapper>
        </StickyHeader>
      )}

      <Switch>
        <Route path={`${path}/menu/:menuGroupId`}>
          <OutletStackedMenuView
            activeMenuId={activeMenuId}
            setActiveMenuId={setActiveMenuId}
            manualNavigation={manualNavigation}
          />
        </Route>
        <Route path={`${path}/menu`}>
          <MenuWrapper headerHeight={headerHeight}>
            <ObservableDiv ref={expandHeaderRef} aria-hidden />
            {!isStackedMenu ? (
              <OutletMenu
                activeMenuId={activeMenuId}
                allDiscounts={data}
                setActiveMenuId={setActiveMenuId}
                fullMenuItemGroupTree={fullMenuItemGroupTree}
                availableMenuItemGroupTree={menuItemGroupTree}
                manualNavigation={manualNavigation}
                discountsLoading={discountsLoading}
                menuSearchIds={menuSearchIds}
                searchTerm={searchTerm}
                showMenuSearchResults={showMenuSearchResults}
              />
            ) : (
              <OutletStackedMenu allDiscounts={data} />
            )}
          </MenuWrapper>
        </Route>

        <Redirect exact from={`${path}`} to={`${path}/menu`} />
        <Redirect
          exact
          from={`${path}/info`}
          to={`${path}/menu?showDetails=1`}
          passthroughQueryParams={false}
        />
      </Switch>
    </>
  )
}
