import * as Types from '../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type getBusinessSegmentsQueryVariables = Types.Exact<{
  marketplaceId: Types.Scalars['String']
  outletsInput: Types.OutletsInput
  businessSegmentTypes?: Types.InputMaybe<
    Array<Types.BusinessSegmentType> | Types.BusinessSegmentType
  >
  fulfilmentMethod: Types.NarrowFulfilmentMethodInputType
  acceptedFulfilmentMethods?: Types.InputMaybe<
    | Array<Types.NarrowFulfilmentMethodInputType>
    | Types.NarrowFulfilmentMethodInputType
  >
  fulfilmentLocation?: Types.InputMaybe<Types.FulfilmentLocationInput>
  fulfilmentTimeType?: Types.InputMaybe<Types.FulfilmentTimeType>
}>

export type getBusinessSegmentsQuery = {
  businessSegments: Array<{
    __typename: 'BusinessSegment'
    id: string
    name: string
    showName: boolean
    type: Types.BusinessSegmentType
    availabilityEndDate: string | null
    availabilityStartDate: string | null
    availabilityTimes: unknown | null
    description: string | null
    orderBy: Types.SegmentOrderBy | null
    outletLimit: number
    position: number
    showDiscountLabels: boolean
    showFeaturedFlags: boolean
    linkToSegmentIndex: boolean
    cards: Array<
      | {
          __typename: 'Cuisine'
          id: string
          key: string | null
          name: string
          emoji: string | null
          imageSrc: string | null
        }
      | {
          __typename: 'LinkCard'
          id: string
          name: string
          url: string | null
          buttonText: string | null
          image: string | null
          description: string | null
          outlet: {
            __typename: 'Outlet'
            id: string
            name: string
            displayName: string
          } | null
          menuItem: { __typename: 'MenuItem'; id: string; name: string } | null
        }
      | {
          __typename: 'Outlet'
          id: string
          name: string
          displayName: string
          description: string | null
          isOpen: boolean
          isOnline: boolean
          isOrderable: boolean
          closedUntil: string | null
          paymentMethods: Array<string>
          deliveryMinimumOrderValue: number | null
          collectionMinimumOrderValue: number | null
          ASAPDeliveryDuration: number | null
          prepTime: number
          distanceFromUserKM: number | null
          noPreordersBeforeOpening: boolean
          daysOfferedInAdvanceMin: number
          daysOfferedInAdvanceMax: number
          nextOpenDate: string
          coverImage: string | null
          featured: boolean
          availableFulfillmentMethods: Array<Types.FulfillmentMethodType>
          availableFulfilmentInputMethods: Array<Types.NarrowFulfilmentMethodInputType>
          collectionPreorderTimes: Array<string>
          outletLogoOverride: string | null
          fulfilmentRange: string | null
          openingTimesArray: Array<{
            __typename: 'OpeningTime'
            id: string
            timeSlot: number
            start: {
              __typename: 'DayAndTime'
              id: string
              day: number
              time: string
            }
            end: {
              __typename: 'DayAndTime'
              id: string
              day: number
              time: string
            }
          }>
          outletLoyaltyCards: Array<{
            __typename: 'LoyaltyCard'
            id: string
            requiredStamps: number
            loyaltyCardIcon: string
            loyaltyCardColor: string
            discount: { __typename: 'Discount'; id: string; name: string }
            loyaltyCardStamps: Array<{
              __typename: 'LoyaltyCardStamp'
              id: string
            }>
          }>
          availableFulfilments: Array<{
            __typename: 'AvailableFulfilment'
            fulfilmentTimeTypes: Array<Types.FulfilmentTimeType>
            fulfilmentMethod: Types.NarrowFulfilmentMethodInputType
          }>
          specialOfferFlags: {
            __typename: 'SpecialOfferFlags'
            collectionDeal: string | null
            deliveryDeal: string | null
            tableDeal: string | null
          }
          outletCuisines: Array<{
            __typename: 'Cuisine'
            id: string
            name: string
            emoji: string | null
            imageSrc: string | null
            key: string | null
          }>
          outletAllDiscounts: Array<{
            __typename: 'Discount'
            id: string
            name: string
            customerEnrolled: boolean
            discountAmount: number | null
            discountPercentage: number | null
            minimumSubtotalGross: number | null
          }>
          statusText: {
            __typename: 'OutletStatusText'
            orderButtonTranslation: string
            openingTimeTranslation: {
              __typename: 'OpeningTimeTranslation'
              key: string
              datetime: string | null
              datetimeFormat: string | null
              days: string | null
            }
          }
          outletAddress: {
            __typename: 'Address'
            id: string
            key: string | null
            name: string
            city: string
            firstLine: string
            secondLine: string | null
            thirdLine: string | null
            countryCode: Types.CountryCodeType
            default: boolean
            postcode: string
          }
          restaurant: {
            __typename: 'Restaurant'
            id: string
            image: string | null
            name: string
          }
        }
    >
  }>
}

export const getBusinessSegmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBusinessSegments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'marketplaceId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'outletsInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'OutletsInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'businessSegmentTypes' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'BusinessSegmentType' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fulfilmentMethod' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'NarrowFulfilmentMethodInputType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'acceptedFulfilmentMethods' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'NarrowFulfilmentMethodInputType',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fulfilmentLocation' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'FulfilmentLocationInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fulfilmentTimeType' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'FulfilmentTimeType' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'businessSegments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'marketplaceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'marketplaceId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'businessSegmentTypes' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'businessSegmentTypes' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'showName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availabilityEndDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availabilityStartDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availabilityTimes' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'orderBy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'outletLimit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'showDiscountLabels' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'showFeaturedFlags' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkToSegmentIndex' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cards' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'outletsInput' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'outletsInput' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'Outlet' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isOpen' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isOnline' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isOrderable' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'openingTimesArray',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'start' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'day' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'time' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'end' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'day' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'time' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timeSlot' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'outletLoyaltyCards',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'requiredStamps',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'loyaltyCardIcon',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'loyaltyCardColor',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'discount' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'loyaltyCardStamps',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'closedUntil' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'paymentMethods' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'deliveryMinimumOrderValue',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'collectionMinimumOrderValue',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'ASAPDeliveryDuration',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'prepTime' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'distanceFromUserKM',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'noPreordersBeforeOpening',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'daysOfferedInAdvanceMin',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'daysOfferedInAdvanceMax',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nextOpenDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'coverImage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'featured' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'availableFulfillmentMethods',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'availableFulfilmentInputMethods',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'availableFulfilments',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'fulfilmentTimeTypes',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'fulfilmentMethod',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'collectionPreorderTimes',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'outletLogoOverride',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'specialOfferFlags',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'collectionDeal',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'deliveryDeal',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'tableDeal' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'outletCuisines' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'emoji' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'imageSrc' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'key' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'outletAllDiscounts',
                              },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: {
                                    kind: 'Name',
                                    value: 'fulfilmentMethod',
                                  },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'fulfilmentMethod',
                                    },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'customerEnrolled',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'discountAmount',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'discountPercentage',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'minimumSubtotalGross',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusText' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: {
                                    kind: 'Name',
                                    value: 'acceptedFulfilmentMethods',
                                  },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'acceptedFulfilmentMethods',
                                    },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: {
                                    kind: 'Name',
                                    value: 'fulfilmentTimeType',
                                  },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'fulfilmentTimeType',
                                    },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'openingTimeTranslation',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'key' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'datetime',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'datetimeFormat',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'days' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'orderButtonTranslation',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fulfilmentRange' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: {
                                    kind: 'Name',
                                    value: 'acceptedFulfilmentMethods',
                                  },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'acceptedFulfilmentMethods',
                                    },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: {
                                    kind: 'Name',
                                    value: 'fulfilmentLocation',
                                  },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'fulfilmentLocation',
                                    },
                                  },
                                },
                              ],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'outletAddress' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'key' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'city' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstLine' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'secondLine' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'thirdLine' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'countryCode',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'default' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'postcode' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'restaurant' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'image' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'LinkCard' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'buttonText' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'outlet' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'displayName',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'image' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'menuItem' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'Cuisine' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'key' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'emoji' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageSrc' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  getBusinessSegmentsQuery,
  getBusinessSegmentsQueryVariables
>
