import styled, { css } from 'styled-components'

import { LinkToOutlet } from '@src/components/LinkToOutlet'
import { breakpoints } from '@src/constants/breakpoints'

import { SingleOutletDirection } from './types'

import { Button } from '../Button'
import { DotSpan } from '../DotSpan/DotSpan'
import { CouponManySVG } from '../SVGS/CouponManySVG'
import { CouponSVG } from '../SVGS/CouponSVG'
import { StarSVG } from '../SVGS/StarSVG'

export const LongSquareButton = styled(Button)<{ status: string }>`
  height: 32px;
  width: auto;
  border-radius: 24px;
  font-size: 11px;
  font-weight: 700;
  padding: 0 14px;
  min-height: 32px;
  border: none;

  @media (min-width: ${breakpoints.tablet}px) {
    height: 32px;
  }
  text-transform: uppercase;

  ${({ theme, status }) => {
    if (status === 'open' || status === 'order') {
      return css`
        color: ${theme.colors.openStatus};
        background-color: ${`${theme.colors.openStatus}20`};
      `
    }
    if (status === 'pre_order') {
      return css`
        color: ${theme.colors.preorderStatus};
        background-color: ${`${theme.colors.preorderStatus}20`};
      `
    }
    return css`
      color: #939393;
      background-color: #93939320;
    `
  }}
`

export const LinkToOutletContainer = styled(LinkToOutlet)<{ status: string }>`
  box-sizing: border-box;
  margin: 0 0 0 16px;
  width: 240px;
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.tablet}px) {
    width: 298px;
    margin: 0 0 0 28px;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      ${LongSquareButton} {
        ${({ theme, status }) => {
          if (status === 'open' || status === 'order') {
            return css`
              color: #fff;
              background-color: ${theme.colors.openStatus};
            `
          }
          if (status === 'pre_order') {
            return css`
              color: #fff;
              background-color: ${theme.colors.preorderStatus};
            `
          }
          return css`
            color: #fff;
            background-color: #939393;
          `
        }}
      }
    }
  }
`

export const InfoContainer = styled.div`
  @media (min-width: ${breakpoints.tablet}px) {
    flex: 1;
  }
`
export const RestaurantContainer = styled.div<{
  direction: SingleOutletDirection
  hasOffer: boolean
  isSlide: boolean
}>`
  all: unset;
  display: flex;
  flex-direction: ${props => props.direction};
  border-radius: 8px;
  position: relative;
  transition: all 0.3s;
  cursor: pointer;
  margin: 0 0 8px 0;
  margin-top: ${({ isSlide }) => (isSlide ? '0px' : '8px')};
  background-color: ${({ theme }) => theme.colors.brandForeground};

  @media (min-width: ${breakpoints.tablet}px) {
    margin-top: ${({ isSlide }) => (isSlide ? '0px' : '24px')};
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    h5 {
      color: ${({ theme }) => theme.colors.brand};
    }
  }

  ${props =>
    props.direction === SingleOutletDirection.ROW &&
    css`
      @media (max-width: ${breakpoints.tablet}px) {
        ${CoverImage} {
          height: 100%;
          max-height: 249px;
          border-radius: 8px 8px 8px 8px;
          flex-shrink: 0;

          @media (min-width: ${breakpoints.tablet}px) {
            width: 32%;
            border-radius: 4px 0 0 4px;
          }
        }

        ${OutletName} {
          margin-top: 0px;
        }

        ${OutletDetailsContainer} {
          width: 59%;
          height: 100%;

          @media (min-width: ${breakpoints.phablet}px) {
            width: 68%;
          }
        }

        ${OutletOpeningContainer} {
          @media (min-width: ${breakpoints.phablet}px) {
            flex-direction: row;
          }
        }

        ${OpenStatusContainer} {
          @media (max-width: ${breakpoints.phablet}px) {
          }
        }
      }
    `}
`

export const CuisineDot = styled(DotSpan)`
  span {
    margin-right: 6px;
  }
`

export const OutletDetailsContainer = styled.div<{
  isSlide: boolean
}>`
  padding: 0px 16px 16px 16px;
  margin: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${breakpoints.tablet}px) {
    width: auto;
  }
`

export const OutletName = styled.h5`
  margin: 0px;
  font-size: 14px;
  font-weight: 700;
  margin-top: 12px;
  color: ${({ theme }) => theme.colors.mainText};

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 16px;
  }
`

export const CuisinesContainer = styled.div<{ isSlide: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 0 8px 0;
  align-content: flex-start;
  flex: 1;
  height: 24px;

  @media (min-width: ${breakpoints.tablet}px) {
    margin-bottom: 0;
  }
`

export const CuisineTag = styled.div`
  padding: -10px;
  display: flex;
  flex-direction: row;
  font-size: 10px;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 12px;
  }

  span {
    margin-right: 4px;
    display: flex;
    justify-items: center;
    align-items: center;
    padding-bottom: 2px;

    @media (min-width: ${breakpoints.tablet}px) {
      margin-right: 5px;
      height: 14px;
    }
  }
`

export const Cuisine = styled.p`
  margin: 0 6px 0 0;
  font-size: 12px;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.6;

  @media (min-width: ${breakpoints.tablet}px) {
    margin-right: 4px;
  }
`

export const OutletOpeningContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`

export const OutletTranslationContainer = styled.div`
  font-size: 12px;

  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  margin: 0 4px 0 0;
`

export const OutletTranslationText = styled.div<{
  openingStatus: string
  isOutletPage: boolean | undefined
}>`
  font-weight: 700;
  color: ${({ openingStatus, theme }) => {
    if (openingStatus === 'open' || openingStatus === 'order') {
      return theme.colors.openStatus
    }
    if (openingStatus === 'pre_order') {
      return theme.colors.preorderStatus
    } else return 'black'
  }};
`

export const OpenStatusContainer = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: auto;
  justify-content: flex-end;
`

export const PaddedDotSpan = styled.span`
  margin: 0 2px 0 4px;
`

export const CoverImage = styled.div<{
  imageUrl: string
}>`
  height: 164px;
  background-color: #c4c4c4;
  border-radius: 8px 8px 0 0;
  position: relative;
  flex-shrink: 0;
  background-color: #c4c4c4;
  background-size: cover;
  background-position: 50%;

  ${props =>
    props.imageUrl &&
    css`
      background-image: url(${props.imageUrl});
    `};

  @media (min-width: ${breakpoints.tablet}px) {
    height: 176px;
  }
`

export const RestaurantLogo = styled.div<{
  imageUrl: string
  isSlide: boolean
}>`
  ${({ imageUrl, isSlide }) => css`
    height: 80px;
    width: 80px;
    top: 50px;
    left: 16px;
    border-radius: 4px;
    background-size: cover;
    background-position: 50%;
    margin-right: 12px;
    z-index: 1;

    ${imageUrl &&
    css`
      background-image: url(${imageUrl});
    `};

    ${isSlide &&
    css`
      height: 72px;
      width: 72px;
      position: absolute;
      margin-right: 0;
    `}

    @media (min-width: ${breakpoints.tablet}px) {
      height: 72px;
      top: 56px;
      width: 72px;
      position: absolute;
      margin-right: 0;
    }
  `}
`

export const DiscountName = styled.p`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  margin: 0;
  padding: 0px 24px 0 16px;
  overflow: hidden;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 12px;
  }
`

export const StyledDiscountSVG = styled(CouponSVG)`
  width: 16px;
  height: 16px;
  margin-right: 6px;
`

export const StyledDiscountManySVG = styled(CouponManySVG)`
  width: 16px;
  height: 16px;
  margin-right: 6px;
`

export const DiscountContainer = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  color: ${({ theme }) => theme.colors.brandForeground};
  width: fit-content;
  border-radius: 0px 16px 16px 0px;
  background-color: ${({ theme }) => theme.colors.discount};
  position: relative;
  top: 124px;
  z-index: 2;
  margin-left: 0px;
  border-radius: 0px;
  width: 100%;
  opacity: 0.9;
  @media (min-width: ${breakpoints.tablet}px) {
    top: 144px;
  }
`

export const DiscountsContainer = styled.div`
  margin: 8px 0px;
  position: absolute;
  width: 240px;

  @media (min-width: ${breakpoints.tablet}px) {
    width: 298px;
    margin: 0px;
    height: 0;
    display: flex;
  }
`

export const OfferContainer = styled.div`
  text-align: left;
  background: ${({ theme }) => theme.colors.flag};
  color: ${({ theme }) => theme.colors.brandForeground};
  border-radius: 0px 8px 8px 0px;
  display: flex;
  align-items: flex-start;
  padding: 2px 10px 2px 16px;
  position: absolute;
  z-index: 1;
  top: 8px;
  left: 16px;

  @media (min-width: ${breakpoints.tablet}px) {
    max-width: 270px;
    padding: 0px 10px 0px 16px;
    border-radius: 0px 8px 8px 0px;
    position: absolute;
    z-index: 1;
    top: 8px;
    left: 28px;
    right: auto;
  }
`

export const OfferText = styled.p`
  width: 100%;
  font-size: 12px;
  font-weight: 700;
  margin: 6px 0;
  @media (min-width: ${breakpoints.tablet}px) {
    margin: 8px 0px;
    line-height: 14px;
  }
`
export const DiscountsAndLoyaltyCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const OrderInfoContainer = styled.div`
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: #2f3333;
  opacity: 0.6;
`
export const StarSVGStyled = styled(StarSVG)`
  width: 16px;
  height: 16px;
  margin-top: 6px;
  margin-right: 6px;
  color: white;
`

export const LoyaltyCardStampAndIconContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin: 6px 0;
  height: 24px;
`

export const LoyaltyCardTextContainer = styled.div<{ textColor: string }>`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: ${({ textColor }) => textColor};
`

export const ImageContainer = styled.div<{ imageCount: number }>`
  display: flex;

  flex-direction: row;
  align-items: center;
  margin-right: ${({ imageCount }) =>
    `calc(32px + ( ${imageCount - 1} * 10px ))`};

  position: relative;
`

export const LoyaltyCardImage = styled.div<{
  imageUrl: string
  index: number
  imageCount: number
}>`
  background-size: cover;
  min-width: 24px;
  min-height: 24px;
  background-color: grey;
  border-radius: 30px;
  filter: drop-shadow(-2px 2px 2px rgba(0, 0, 0, 0.2));
  position: absolute;
  left: ${({ index, imageCount }) => (imageCount - index - 1) * 10}px;

  ${props =>
    props.imageUrl &&
    css`
      background-image: url(${props.imageUrl});
    `};

  clip-path: url(#scalloped-mask-0.42);
`
